import React from 'react';
import styled from 'styled-components';
import { P, Subtitle5 } from './Typography';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 25%;
  border: 1px solid #dbe3eb;
  border-radius: 10px;
  margin-right: 2rem;
  :nth-child(1) {
    header {
      justify-content: flex-end;
    }
  }
  :nth-child(4) {
    img {
      align-self: center;
    }
  }
  &:last-child {
    margin-right: 0;
  }
  @media (max-width: 1200px) {
    flex-basis: 100%;
    margin-right: 0;
    margin-bottom: 1rem;
  }
`;

const Header = styled.header`
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  min-height: 10rem;
  background: linear-gradient(81.7deg, #e7edf3 8.96%, #e4e6f0 104.78%);
`;

const Content = styled.div`
  padding: 1.5rem;
  min-height: 10rem;
`;

const Image = styled.img``;

const StyledSubtitle5 = styled(Subtitle5)`
  margin-bottom: 0.8rem;
`;

interface CbdPaymentProps {
  title: string;
  text: string;
  image: string;
}

const CbdPaymentsCard: React.FunctionComponent<CbdPaymentProps> = ({
  title,
  text,
  image,
}) => (
  <Wrapper>
    <Header>
      <Image src={image} />
    </Header>
    <Content>
      <StyledSubtitle5>{title}</StyledSubtitle5>
      <P>{text}</P>
    </Content>
  </Wrapper>
);

export default CbdPaymentsCard;
