import React from 'react';
import styled from 'styled-components';

import Section from './Section';

const FirstViewportWrapper = styled(Section)<{ withRightPadding: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  height: calc(100vh - 70px - 5rem * 2);
  padding-top: 0;
  padding-bottom: 0;
  padding-right: ${(props) => (props.withRightPadding ? `` : 0)};
  @media (max-width: 1200px) {
    flex-wrap: wrap;
    height: auto;
  }
`;

const RightSideContent = styled.div`
  flex: 1 1 30%;
  padding-left: 2rem;
  @media (max-width: 1200px) {
    padding-left: 0;
  }
`;

const LeftImage = styled.img<{ width: string; imagePadding?: string }>`
  max-height: 100%;
  width: ${(props) => props.width && props.width};
  padding: ${({ imagePadding }) => imagePadding ?? 0};
  @media (max-width: 1300px) {
    ${(props) => props.width && `width: auto`};
  }
  @media (max-width: 1200px) {
    max-height: auto;
    width: 100%;
    margin-bottom: 5rem;
    margin-top: 2rem;
    padding-left: 0;
  }
`;
interface ViewportProps {
  img: string;
  withRightPadding?: boolean;
  imgWidth?: string;
  imagePadding?: string;
}
const FirstViewportMirrored: React.FunctionComponent<ViewportProps> = ({
  children,
  img,
  withRightPadding,
  imgWidth,
  imagePadding,
}) => (
  <FirstViewportWrapper withRightPadding={withRightPadding}>
    <LeftImage
      width={imgWidth}
      imagePadding={imagePadding}
      src={img}
      alt="application screnshot"
    />
    <RightSideContent>{children}</RightSideContent>
  </FirstViewportWrapper>
);

export default FirstViewportMirrored;
