import React from 'react';
import styled from 'styled-components';
import { CbdPaymentsPageProps } from '@/pages/marketing/cbd-payments';
import FirstViewport from '@/components/FirstViewport';
import { Body2, Description, H1, H2, P } from '@/components/Typography';
import Vp1Image from '../assets/img/cbdPaymentsVp1.png';
import Vp3Image from '../assets/img/cbdVp3.png';
import Vp4Image from '../assets/img/cbdVp4.png';
import Vp5Image from '../assets/img/cbdVp5.png';
import Vp6Image from '../assets/img/cbdVp6.png';
import Vp7Image from '../assets/img/cbdVp7.png';
import Vp8Image from '../assets/img/cbdVp8Bg.png';
import bannerBg from '../assets/img/bannerFenaTerminal.png';
import Section from '@/components/Section';
import ButtonDefault from '@/components/ButtonDefault';
import CbdPaymentsCard from '@/components/CbdPaymentsCard';
import FirstViewportMirrored from '@/components/FirstViewportMirrored';
import CbdPluginCard from '@/components/CbdPluginCard';
import BottomMessageType2 from '@/components/BottomMessageType2';

const SecondViewport = styled(Section)``;

const ThirdViewport = styled(FirstViewportMirrored)``;

const FourthViewport = styled(FirstViewport)`
  padding: 0;
`;

const FifthViewport = styled(FirstViewportMirrored)``;

const SixthViewport = styled(FirstViewport)``;

const SeventhViewport = styled(FirstViewportMirrored)``;

const EigthViewport = styled(Section)`
  padding: 5rem calc((100vw - 1500px) / 2);
  padding-bottom: 10rem;
  background-color: #fff;
  background-image: ${(props) => `url(${props.resource})`};
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 1200px) {
    padding: 5rem 2rem 1rem 2rem;
  }
`;

const NinthViewport = styled(Section)`
  background-color: #fff;
  padding-top: 0.5rem;
  @media (max-width: 1200px) {
    padding-top: 0.5;
  }
`;

const ViewportsWrapper = styled.div`
  background-color: #fff;
`;

const StyledH2 = styled(H2)`
  text-align: center;
`;

const StyledBody2 = styled(Body2)`
  margin-top: 1.5rem;
`;

const PluginBody2 = styled(StyledBody2)`
  text-align: center;
  margin-top: 2rem;
  :nth-of-type(2n) {
    margin-top: 7rem;
  }
  :nth-of-type(3n) {
    margin-top: 7rem;
  }
  @media (max-width: 1200px) {
    :nth-of-type(2n) {
      margin-top: 3rem;
    }
    :nth-of-type(3n) {
      margin-top: 3rem;
    }
  }
`;

const StyledDescription = styled(Description)`
  text-align: center;
  &:first-of-type {
    margin-top: 1rem;
  }
`;

const PaymentsCardWrap = styled.div`
  display: flex;
  margin-top: 3rem;
  flex-wrap: nowrap;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
`;

const PluginCardWrapper = styled.div`
  display: flex;
  margin-top: 1.5rem;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
  :nth-of-type(2n) {
    justify-content: center;
    gap: 2rem;
  }
  :nth-of-type(3n) {
    justify-content: center;
    gap: 2rem;
  }
  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
`;

const Text = styled.div`
  @media (max-width: 1200px) {
    margin-bottom: 3rem;
  }
`;

interface CbdPaymentsProps {
  isForBusinessTools?: boolean;
  content: CbdPaymentsPageProps;
}

const CbdPaymentsContainer: React.FunctionComponent<CbdPaymentsProps> = ({
  isForBusinessTools,
  content: {
    viewport1,
    viewport2,
    viewport3,
    viewport4,
    viewport5,
    viewport6,
    viewport7,
    viewport8,
    viewport9,
  },
}) => (
  <>
    <FirstViewport img={Vp1Image}>
      <H1>
        {viewport1.title1}
        {` `}
        <span className="accent-text">{viewport1.title2}</span>
        {` `}
        {viewport1.title3}
      </H1>
      <H1>{viewport1.title4}</H1>
      <H1>{viewport1.title5}</H1>
      <H1>{viewport1.title6}</H1>
      {isForBusinessTools ? (
        <Description className="accent-text-black-bold">
          <span className="accent-text-red">{viewport1.description1}</span>
          {` `}
          {viewport1.description2}
          {` `}
        </Description>
      ) : (
        <Description className="accent-text-black-bold">
          {viewport1.description1}
          {` `}
          <span className="accent-text-red">{viewport1.description2}</span>
          {` `}
          {viewport1.description3}
        </Description>
      )}

      <ButtonDefault to={viewport1.button.url}>
        {viewport1.button.label}
      </ButtonDefault>
    </FirstViewport>
    <ViewportsWrapper>
      <SecondViewport>
        <StyledH2>{viewport2.title}</StyledH2>
        <StyledDescription className="accent-text-black-bold">
          {viewport2.boldDescription1}
          {` `}
          <span className="accent-text-red">{viewport2.boldDescription2}</span>
        </StyledDescription>
        <StyledDescription>{viewport2.description}</StyledDescription>
        <PaymentsCardWrap>
          {viewport2.paymentsCards.map((item) => (
            <CbdPaymentsCard
              key={item.title}
              title={item.title}
              text={item.text}
              image={item.image}
            />
          ))}
        </PaymentsCardWrap>
      </SecondViewport>
      <ThirdViewport img={Vp3Image} imgWidth="50%" withRightPadding>
        <H1>
          {viewport3.title1}
          <span className="accent-text"> {viewport3.title2}</span>
          {` `}
        </H1>
        {viewport3.titleAndTextItems.map((item) => (
          <>
            <StyledBody2>{item.title}</StyledBody2>
            <P>{item.text}</P>
          </>
        ))}
      </ThirdViewport>
      <FourthViewport img={Vp4Image} imgWidth="50%" withRightPadding>
        <H1>
          <span className="accent-text">{viewport4.title1}</span>
          {` `}
          {viewport4.title2}
        </H1>
        {viewport4.titleAndTextItems.map((item) => (
          <>
            <StyledBody2>{item.title}</StyledBody2>
            <P>{item.text}</P>
          </>
        ))}
      </FourthViewport>
      <FifthViewport img={Vp5Image} imgWidth="50%" withRightPadding>
        <H1>
          {viewport5.title1}
          {` `}
          <span className="accent-text">{viewport5.title2}</span>
          {` `}
          {viewport5.title3}
        </H1>
        {viewport5.titleAndTextItems.map((item) => (
          <>
            <StyledBody2>{item.title}</StyledBody2>
            <P>{item.text}</P>
          </>
        ))}
      </FifthViewport>
      <SixthViewport img={Vp6Image} imgWidth="50%" withRightPadding>
        <H1>
          {viewport6.title1}
          {` `}
          <span className="accent-text">{viewport6.title2}</span>
          {` `}
          {viewport6.title3}
        </H1>
        {viewport6.titleAndTextItems.map((item) => (
          <>
            <StyledBody2>{item.title}</StyledBody2>
            <P>{item.text}</P>
          </>
        ))}
      </SixthViewport>
      <SeventhViewport img={Vp7Image} imgWidth="50%" withRightPadding>
        <H1>{viewport7.title}</H1>
        {viewport7.titleAndTextItems.map((item) => (
          <Text>
            <StyledBody2>{item.title}</StyledBody2>
            <P>{item.text}</P>
          </Text>
        ))}
      </SeventhViewport>
    </ViewportsWrapper>
    <EigthViewport resource={Vp8Image}>
      <StyledH2>{viewport8.title}</StyledH2>
      <PluginBody2>{viewport8.subtitle1}</PluginBody2>
      <PluginCardWrapper>
        {viewport8.eCommerceCards.map((item) => (
          <CbdPluginCard key={item.image} imageSrc={item.image} />
        ))}
      </PluginCardWrapper>
      <PluginBody2>{viewport8.subtitle2}</PluginBody2>
      <PluginCardWrapper>
        {viewport8.marketplaceCards.map((item) => (
          <CbdPluginCard key={item.image} imageSrc={item.image} />
        ))}
      </PluginCardWrapper>
      <PluginBody2>{viewport8.subtitle3}</PluginBody2>
      <PluginCardWrapper>
        {viewport8.accountancyCards.map((item) => (
          <CbdPluginCard key={item.image} imageSrc={item.image} />
        ))}
      </PluginCardWrapper>
    </EigthViewport>
    <NinthViewport>
      <BottomMessageType2
        bannerLabel1={viewport9.bannerLabel1}
        bannerLabel2={viewport9.bannerLabel2}
        bannerLabel3={viewport9.bannerLabel3}
        bannerDescription={viewport9.bannerDescription}
        button={viewport9.button}
        bannerBg={bannerBg}
      />
    </NinthViewport>
  </>
);

export default CbdPaymentsContainer;
